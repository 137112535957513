<template>
  <div style="width: 3.7rem;" class="bg-nav navigation navbar navbar-light justify-content-center px-4 px-lg-2 py-2 py-md-3 border-right" v-if="isNavbarVisible">
    <!-- Brand -->
    <router-link to="/dashboard/index" class="mb-0 mb-lg-3 brand-icon">
      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 64 80" fill="none">
                <path d="M58.8996 22.7L26.9996 2.2C23.4996 -0.0999999 18.9996 0 15.5996 2.5C12.1996 5 10.6996 9.2 11.7996 13.3L15.7996 26.8L3.49962 39.9C-3.30038 47.7 3.79962 54.5 3.89962 54.6L3.99962 54.7L36.3996 78.5C36.4996 78.6 36.5996 78.6 36.6996 78.7C37.8996 79.2 39.1996 79.4 40.3996 79.4C42.9996 79.4 45.4996 78.4 47.4996 76.4C50.2996 73.5 51.1996 69.4 49.6996 65.6L45.1996 51.8L58.9996 39.4C61.7996 37.5 63.3996 34.4 63.3996 31.1C63.4996 27.7 61.7996 24.5 58.8996 22.7ZM46.7996 66.7V66.8C48.0996 69.9 46.8996 72.7 45.2996 74.3C43.7996 75.9 41.0996 77.1 37.9996 76L5.89961 52.3C5.29961 51.7 1.09962 47.3 5.79962 42L16.8996 30.1L23.4996 52.1C24.3996 55.2 26.5996 57.7 29.5996 58.8C30.7996 59.2 31.9996 59.5 33.1996 59.5C35.0996 59.5 36.9996 58.9 38.6996 57.8C38.7996 57.8 38.7996 57.7 38.8996 57.7L42.7996 54.2L46.7996 66.7ZM57.2996 36.9C57.1996 36.9 57.1996 37 57.0996 37L44.0996 48.7L36.4996 25.5V25.4C35.1996 22.2 32.3996 20 28.9996 19.3C25.5996 18.7 22.1996 19.8 19.8996 22.3L18.2996 24L14.7996 12.3C13.8996 8.9 15.4996 6.2 17.3996 4.8C18.4996 4 19.8996 3.4 21.4996 3.4C22.6996 3.4 23.9996 3.7 25.2996 4.6L57.1996 25.1C59.1996 26.4 60.2996 28.6 60.2996 30.9C60.3996 33.4 59.2996 35.6 57.2996 36.9Z" fill="black"/>
            </svg> -->
      <img class="img-fluid" style="margin-left: auto !important; margin-right: auto !important; max-height: 90px" src="/images/logo2T.png" alt="" />
    </router-link>

    <!-- Menu: icon -->
    <ul class="nav navbar-nav flex-row flex-sm-column flex-grow-1 justify-content-start py-2 py-lg-0">
      <!-- Create group -->
      <li class="nav-item">
        <a class="nav-link text-light p-2 p-lg-3 d-block d-xl-none menu-toggle me-2 me-lg-0" href="javascript:void(0);" @click="sidebarToggle()"><i class="fa fa-bars"></i></a>
      </li>
      <div class="mt-3 ">
        <li class="nav-item">
          <button style="width: 2.5rem" class="btn btn-light mb-3"><i class="fa-solid fa-lightbulb"></i></button>
        </li>
        <li class="nav-item">
          <button @click="verSideBar(1)" style="width: 2.5rem" class="btn btn-light mb-3"><i class="fa-regular fa-note-sticky"></i></button>
        </li>
        <li class="nav-item">
          <button style="width: 2.5rem" class="btn btn-primary mb-3"><i class="fa-solid fa-desktop"></i></button>
        </li>
        <li class="nav-item">
          <button style="width: 2.5rem" class="btn btn-primary mb-3"><i class="fa-solid fa-microphone"></i></button>
        </li>
        <li class="nav-item">
          <button style="width: 2.5rem" class="btn btn-primary mb-3"><i class="fa-solid fa-users-line"></i></button>
        </li>
        <li class="nav-item">
          <button @click="verSideBar(2)" style="width: 2.5rem" class="btn btn-light mb-3"><i class="fa-solid fa-calculator"></i></button>
        </li>
        <li class="nav-item">
          <button style="width: 2.5rem" class="btn btn-primary mb-3"><i class="fa-solid fa-calendar"></i></button>
        </li>
      </div>
      <!-- <li class="nav-item"><a class="nav-link p-2 p-lg-3" href="javascript:void(0);" title="Search" v-b-modal.SearchModal><i class="fa fa-search"></i></a></li> -->
      <!-- <li class="nav-item"><a class="nav-link p-2 p-lg-3" href="javascript:void(0);" title="notifications" v-b-modal.notificationsModal><i class="fa fa-bell-o"></i></a></li> -->
      <!-- <li class="nav-item"><a class="nav-link p-2 p-lg-3" href="javascript:void(0);"  v-b-modal.LayoutModal><i class="fa fa-sliders"></i></a></li> -->
      <!-- <li class="nav-item d-none d-sm-block"><router-link to="/chat" class="nav-link p-2 p-lg-3"><i class="fa fa-comments-o"></i></router-link></li> -->
      <!-- <li class="nav-item d-none d-sm-block flex-grow-1"><router-link to="/calendar" class="nav-link p-2 p-lg-3"><i class="fa fa-calendar"></i></router-link></li> -->

      <!-- Menu collapse -->
      <li class="nav-item">
        <a class="nav-link p-2 p-lg-3" href="javascript:void(0);" title="Settings" v-b-modal.SettingsModal><i class="fa fa-gear"></i></a>
      </li>
      <li class="nav-item d-none d-sm-block">
        <router-link to="/pages/page-profile" class="nav-link p-2 p-lg-3"><i class="fa fa-user"></i></router-link>
      </li>
    </ul>
    <b-sidebar @hidden="ocultarSideBar(1)" :visible="verNotasRapidas" class="" id="sidebar-right" backdrop bg-variant="transparent" right no-header>
      <!-- <div class="bg-primary px-2 py-2 mr-3 mt-2">
                <b-button style="margin-left: -160px !important;" class="" variant="danger" @click="hide"><i class="fa-solid fa-xmark"></i></b-button>
                <label class="pt-1 pl-2 text-center text-white">
                    Notas rapidas
                </label>
            </div> -->
      <div class=" mx-3 mt-3 notas-rapidas-form card">
        <b-form @submit.prevent="saveData">
          <div class="d-flex bg-primary px-2">
            <h5 class="text-left mt-2 nota-rapida-titulo">Nota rápida</h5>

            <!-- <button class="btn btn-primary py-0 my-0" style="margin-left: auto; height: 2.2rem"><i class="fa-solid fa-thumbtack"></i></button> -->
          </div>
          <div class="p-2">            
            <div class="py-1 mt-2 titulo-notas-rapidas ">
              <input
                required
                v-model="row.titulo"
                type="text"
                class="form-control-notas-rapidas"
                id="exampleInputEmail1"
                placeholder="Escribe un titulo"
                aria-describedby="emailHelp"
              />
            </div>
            <textarea required v-model="row.texto" class="form-control-notas-rapidas" id="exampleFormControlTextarea1" placeholder="Descripción..." rows="3"></textarea>
            <div class="d-flex justify-content-end mt-2">
              <!-- <b-form-checkbox class="text-right pr-3 mt-1" id="checkbox-1" v-model="row.estado" name="checkbox-1" value="accepted" style="margin-right: auto">
                Privada
              </b-form-checkbox> -->
              <b-form-checkbox
                switches
                class="text-right pr-3 mt-1"
                size="small"
                id="checkbox-1"
                name="checkbox-1"
                :value="'Personal'"
                :unchecked-value="'Empresa'"
                v-model="row.estado"
              >
                <span for="observaciones" class="h6 text-light">Personal</span>
              </b-form-checkbox>
              <button class="btn btn-dark mr-1">Cancelar</button>
              <button type="submit" class="btn btn-primary">guardar</button>
            </div>
          </div>
        </b-form>
      </div>
      <div class="p-2 mx-3 mt-2 notas-rapidas text-light text-left" v-for="itm of items" :key="itm.id">
        <div class="d-flex">
          <!-- <h5 class="text-left"></h5> -->
          <input
            required
            v-model="itm.titulo"
            type="text"
            class="form-control-notas-rapidas"
            id="exampleInputEmail1"
            placeholder="Escribe un titulo"
            aria-describedby="emailHelp"
          />
          <button class="btn btn-light ml-2" style="margin-left: auto; width: 2.1rem; height: 2.1rem;"><i class="fa-solid fa-trash pb-0 mb-0" style="font-size: 1rem"  ></i></button>
        </div>
        <textarea required v-model="itm.texto" class="form-control-notas-rapidas mt-1" id="exampleFormControlTextarea1" placeholder="Descripción..." rows="3"></textarea>

        <!-- <p class="px-1 mt-2">
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at
          eros.
        </p> -->
      </div>
      <!-- <div class="px-3 py-2">
            <p>
                Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
                in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
            <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img>
            </div> -->
    </b-sidebar>
    <b-sidebar @hidden="ocultarSideBar(2)" :visible="verCalculadora" class="" id="sidebar-right-Calc" backdrop bg-variant="transparent" right no-header>
      <div class=" mx-3 mt-3 notas-rapidas-form card">
        <b-form @submit.prevent="">
          <div class="d-flex bg-primary pt-2 px-2">
            <h5 class="text-left  nota-rapida-titulo">Calculadora</h5>

            <!-- <button class="btn btn-primary py-0 my-0" style="margin-left: auto; height: 2.2rem"><i class="fa-solid fa-thumbtack"></i></button> -->
          </div>
          <div class="py-1 mt-2 titulo-notas-rapidas">
            <input
              readonly
              v-model="rowCal.calculotexto"
              type="text"
              class="form-control-transparent-calculadora-arriba text-right pr-2"
              id="exampleInputEmail1"
              placeholder=""
              aria-describedby="emailHelp"
            />
            <input
              readonly
              v-model="rowCal.calculo"
              type="number"
              class="form-control-transparent-calculadora-abajo  text-right"
              id="exampleInputEmail1"
              placeholder="Escribe un titulo"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="px-2 pb-3">

            <div class="row m-2">
              <div class="col-6 px-1 mx-0">
                <button @click="pulsacion('C')" class=" btn-block btn btn-primary"><b> C </b></button>
              </div>
              <div class="col-6 px-1 mx-0">
                <button @click="pulsacion('←')" class="  btn-block btn btn-light"><b><i class="fa-solid fa-delete-left"></i></b></button>
              </div>
            </div>
            <div class="row m-2">
              <div class="col-3 px-1">              
                <button @click="pulsacion(7)" class="btn-block btn btn-dark"><b>7</b></button>
              </div>
              <div class="col-3 px-1">
                <button @click="pulsacion(8)" class="btn-block btn btn-dark"><b>8</b></button>
              </div>
              <div class="col-3 px-1">
                <button @click="pulsacion(9)" class="btn-block btn btn-dark"><b>9</b></button>
              </div>
              <div class="col-3 px-1">
                <button @click="pulsacion('/', 1)" class="btn-block btn btn-light"><b><i class="fa-solid fa-divide"></i></b></button>
              </div>
            </div>
            <div class="row m-2">
              <div class="col-3 px-1">
                <button @click="pulsacion(4)" class="btn-block btn btn-dark"><b>4</b></button>
              </div>
              <div class="col-3 px-1">              
                <button @click="pulsacion(5)" class="btn-block btn btn-dark"><b>5</b></button>
              </div>
              <div class="col-3 px-1">
                <button @click="pulsacion(6)" class="btn-block btn btn-dark"><b>6</b></button>
              </div>
              <div class="col-3 px-1">
                <button @click="pulsacion('x', 1)" class="btn-block btn btn-light"><b><i class="fa-solid fa-xmark"></i></b></button>
              </div>
            </div>
            <div class="row m-2">
              <div class="col-3 px-1">
                <button @click="pulsacion(1)" class="btn-block btn btn-dark"><b>1</b></button>
              </div>
              <div class="col-3 px-1">
                <button @click="pulsacion(2)" class="btn-block btn btn-dark"><b>2</b></button>
              </div> 
              <div class="col-3 px-1">
                <button @click="pulsacion(3)" class="btn-block btn btn-dark"><b>3</b></button>
              </div>    
              <div class="col-3 px-1">
                <button @click="pulsacion('-', 1)" class="btn-block btn btn-light"><b><i class="fa-solid fa-minus"></i></b></button>
              </div>       
            </div>
            <div class="row m-2">
              <div class="col-3 px-1">
                <button @click="pulsacion('.')" class="btn-block btn btn-light"><b>.</b></button>
              </div>
              <div class="col-3 px-1">
                <button @click="pulsacion(0)" class="btn-block btn btn-dark"><b>0</b></button>
              </div>
              <div class="col-3 px-1">
                <button @click="pulsacion('=', 1)" class="btn-block btn btn-primary"><b><i class="fa-solid fa-equals"></i></b></button>
              </div>
              <div class="col-3 px-1">
                <button @click="pulsacion('+', 1)" class="btn-block btn btn-light"><b><i class="fa-solid fa-plus"></i></b></button>
              </div>
            </div>
            <div class="d-flex px-1 mt-4" >
              <!-- <b-form-checkbox class="text-right pr-3 mt-1" id="checkbox-1" v-model="row.estado" name="checkbox-1" value="accepted" style="margin-right: auto">
                Privada
              </b-form-checkbox> -->
              <b-form-checkbox
                switches
                class="text-left pr-3 mt-1 mr-auto "
                size="small"
                id="checkbox-1"
                name="checkbox-1"
                :value="'Personal'"
                :unchecked-value="'Empresa'"
                v-model="row.estado"
              >
                <span for="observaciones" class="h6 text-light">Personal</span>
              </b-form-checkbox>
              <div class="">
                <button class="btn btn-dark mr-1">Cancelar</button>
                <button type="submit" class="btn btn-primary">guardar</button>
              </div>
            </div>
          </div>
        </b-form>
      </div>
      <!-- <div class="p-2 mx-3 mt-2 notas-rapidas text-light text-left" v-for="itm of items" :key="itm.id">
        <div class="d-flex">
          <input
            required
            v-model="itm.titulo"
            type="text"
            class="form-control-notas-rapidas"
            id="exampleInputEmail1"
            placeholder="Escribe un titulo"
            aria-describedby="emailHelp"
          />
          <button class="btn btn-danger" style="margin-left: auto"><i class="fa-solid fa-xmark"></i></button>
        </div>
        <textarea required v-model="itm.texto" class="form-control-notas-rapidas" id="exampleFormControlTextarea1" placeholder="Descripción..." rows="3"></textarea>


      </div> -->
      <!-- <div class="px-3 py-2">
            <p>
                Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
                in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
            <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img>
            </div> -->
    </b-sidebar>
  </div>
</template>
<script>
import { EventBus } from "@/js/event-bus.js";
export default {
  name: "NavigationComponent",
  data() {
    return {
      table: "meca_notas",
      isSidebarToggle: false,
      currentLayoutStyle: "style-1",
      currentLayout: "layout-a",
      verNotasRapidas: false,
      verCalculadora: false,
      ruta: "",
      row: { estado: "Personal" },
      userId: {},
      company_id: null,
      items: [],
      rowCal: {
        calculo: "0",
        calculotexto: "",
      },
    };
  },
  mounted() {
    // this.getData();
    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    EventBus.$on("activeLayout", (activeLayout) => {
      this.currentLayout = activeLayout.currentLayout;
      this.currentLayoutStyle = activeLayout.currentLayoutStyle;
    });
  },
  computed: {
    isNavbarVisible() {
      return (
        this.currentLayout !== "layout-d" &&
        this.currentLayout !== "layout-d-sub-header" &&
        this.currentLayout !== "layout-f" &&
        this.currentLayout !== "layout-e" &&
        this.currentLayout !== "layout-h"
      );
    },
  },
  methods: {
    pulsacion(digito, signo) {
      //   console.log("pulsado");
      console.log(digito);
      if (signo != undefined) {
        console.log(signo);
        this.rowCal.calculotexto = this.rowCal.calculo == "0" ? "0" + digito : this.rowCal.calculotexto + this.rowCal.calculo + digito;
        this.rowCal.calculo = "0";
      } else {
        if (digito == "C") {
          this.rowCal.calculo = "0";
          this.rowCal.calculotexto = "";
        } else {
          if (digito == "←") {
            this.rowCal.calculo = this.rowCal.calculo.length <= 1 ? "0" || this.rowCal.calculo == "0" : this.rowCal.calculo.substring(0, this.rowCal.calculo.length - 1);
          } else {
            this.rowCal.calculo = this.rowCal.calculo == "0" ? digito.toString() : this.rowCal.calculo.toString() + digito.toString();
          }
        }
      }
    },
    async saveData() {
      try {
        console.log("save");
        let data = Object.assign({}, this.row);
        data.usuario_id = this.userId;
        data.empresa_id = this.company_id;
        data.ruta = this.ruta;
        let res;
        if (this.row.id) {
          delete data.id;
          res = await this.$store.dispatch("put", {
            path: this.table + "/modify/" + this.row.id,
            data: data,
          });
        } else {
          res = await this.$store.dispatch("post", {
            path: this.table + "/new",
            data: data,
          });
        }
        if (res.error) {
          this.$swal({
            title: "Aviso",
            text: "Error: " + res.error,
            icon: "error",
          });
        } else {
          this.getData();
          this.row = {};
          this.$swal({
            title: "Aviso",
            text: "Datos almacenados correctamente",
            icon: "success",
          });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async verSideBar(id) {
      if (id == 1) {
        this.items = [];

        this.ruta = this.$route.path.split("/");
        this.verNotasRapidas = true;
        await this.getData();
      }
      if (id == 2) {
        // this.items = [];
        this.ruta = this.$route.path.split("/");
        this.verCalculadora = true;
        // await this.getData();
      }
    },
    ocultarSideBar(id) {
      console.log("oculto");
      if (id == 1) this.verNotasRapidas = false;
      if (id == 2) this.verCalculadora = false;
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", {
          path: this.table + "/getAllByUserByPath/" + this.userId + "/" + this.ruta,
        });
        console.log(res);
        this.items = res;
        this.totalRows = this.items.length;
      } catch (error) {
        this.items = [];
        this.totalRows = 0;
        console.error("error", error);
      }
    },
    sidebarToggle: function () {
      this.isSidebarToggle = !this.isSidebarToggle;
      EventBus.$emit("isSidebarToggle", this.isSidebarToggle);
    },
  },
  beforeDestroy() {
    EventBus.$off("activeLayout");
  },
};
</script>
<style scoped>
/* .b-sidebar-body{
 background-color: transparent !important;
 background: transparent !important;
} */
</style>
