<template>
  <div class="modal-body bg-gray">
    <!-- <button type="button" class="btn btn-close"></button> -->
    <!-- <form @submit.prevent="saveData()"> -->
    <b-modal id="modalClientes" tabindex="-1" class="modal-lg" size="xl" centered title="Seleccionar cliente" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <customers></customers>
        <!-- <form>
          <div class="row">
            <div class="col-md-12 mb-2">
              <b-table
                hover
                responsive
                :items="listaClientes"
                :fields="fieldsCustomer"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
              >
                <template #cell(action)="data">
                  <span
                    ><button @click.prevent="selCustomer(data.item)" class="btn btn-primary ml-1">
                      <i class="fa fa-check"></i>
                    </button>
                  </span>
                </template>
              </b-table>
            </div>
          </div>
          <div class="text-right">
            <b-button class="btn btn-light mr-2" @click="$bvModal.hide('modalClientes')">Cerrar</b-button>
          </div>
        </form> -->
      </div>
    </b-modal>
    <b-modal id="exampleModalCenter" tabindex="-1" class="modal-lg" centered title="Agregar producto" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="addProductToCart()">
          {{ "indexventa = " + itemSel }}
          <div class="row">
            <div class="col-md-12 mb-2">
              <label for="nombre" class="">Producto:</label>
              <input readonly v-model="row.nombre" class="form-control" type="text" name="nombre" id="nombre" />
            </div>
            <div class="col-md-4 mb-2">
              <label for="nombre" class="">Cantidad:</label>
              <input type="number" min="1" step="0.01" v-model="row.cantidad" class="form-control" name="cantidad" id="cantidad" />
            </div>
            <div class="col-md-4 mb-2">
              <label for="nombre" class="">Precio venta:</label>
              <input required v-model="row.precio_venta" class="form-control" type="text" name="precio_venta" id="precio_venta" />
            </div>
            <div class="col-md-4 mb-2">
              <label for="nombre" class="">Total:</label>
              <input
                readonly
                required
                :value="config.moneda + row.precio_venta * (row.cantidad ? row.cantidad : 1)"
                class="form-control"
                type="text"
                name="precio_venta"
                id="precio_venta"
              />
            </div>
          </div>
          <div class="text-right">
            <b-button class="btn btn-light mr-2" @click="$bvModal.hide('exampleModalCenter')">Cancelar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Agregar" />
          </div>
        </form>
      </div>
    </b-modal>
    <button class="btn btn-primary mb-3" @click.prevent="agregarVenta()" type="button">Crear nueva venta</button>
    <b-tabs v-model="tabIndexs" content-class="mt-3">
      <b-tab @click="itemSel = indexVenta" :title="'Venta-' + Number(venta.id)" v-for="(venta, indexVenta) of carritoVenta" :key="indexVenta">
        <template slot="title">
          {{ "Venta-" + Number(venta.id) }}
          <span @click="cleanSale(itemSel)">
            <i style="color: red" class="fa-solid fa-circle-xmark"></i>
          </span>
        </template>
        <div class="full">
          <form :id="'fr-' + itemSel">
            <!-- {{ itemSel }}
            {{ venta }} -->
            <div class="row">
              <!-- categorias -->
              <div class="col-sm-12 p-0 col-md-8 border border-2">
                <div>
                  <input class="form-control" @keyup="buscar()" type="text" v-model="textoABuscar" name="" id="" placeholder="Texto a buscar..." />
                </div>
                <div class="bg-primary py-0">
                  <h5 class="mt-1 text-light" style="padding: 3px">Categorías</h5>
                </div>
                <!-- <h4> panel cat y prod</h4> -->
                <div class="row">
                  <div class="inner only-this-horizon-scrollbar" style="">
                    <div class="scroll-container diff-size">
                      <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                      <div @click="getProductByCategory(item.id)" class="scroll-section" v-for="item in card_home" :key="item.id">
                        <categories-vue :product="item" class="zoom" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- panel derecho -->
              <div class="">
                <div class="col-sm-12 col-md-4 pr-0 span6 cel_big pull-right text-center">
                  <div class="bg-primary py-0">
                    <h5 class="mt-1 text-light" style="padding: 3px">Resumen</h5>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-2">
                      <label for="estado" class="">Mesa/puesto</label>
                      <b-form-select class="form-control select" v-model="venta.puesto_id" :options="listaPuestos" value-field="id" text-field="nombre"> </b-form-select>
                    </div>
                    <div class="col-md-6 mb-2">
                      <label for="estado" class="">Vendedor</label>
                      <b-form-select required class="form-control select" v-model="venta.usuario_id" :options="listaVendedores" value-field="id" text-field="nombreusuario">
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="text-left mb-1">
                      <span>Fecha:</span>
                    </div>
                    <input class="form-control" type="date" v-model="venta.fecha" name="" id="" />
                  </div>
                  <div class="border">
                    <b-list-group>
                      <div v-for="it of venta.cart" :key="it.id" button>
                        <div class="px-3 hover">
                          <div class="border-top border-bottom row py-3 hover">
                            <div @click="deleteProduct(it, itemSel)" class="col-1 text-right pointer">
                              <i class="fa fa-trash-o text-red" />
                            </div>
                            <div class="col-7 text-left">{{ it.nombre }}</div>
                            <div class="col-2 text-left">{{ config.moneda + (it.precio_venta * it.cantidad).toFixed(2) }}</div>
                          </div>
                        </div>
                      </div>
                    </b-list-group>
                    <div class="row mt-3 px-4">
                      <div class="col-md-12 mb-2 mt-2 text-left">
                        <b-form-checkbox
                          @change="calculateTotal(itemSel, $event)"
                          size="lg"
                          id="checkbox-1"
                          v-model="venta.con_iva"
                          name="checkbox-1"
                          value="1"
                          unchecked-value="0"
                        >
                          <span for="observaciones" class="h6">Iva</span>
                        </b-form-checkbox>
                      </div>
                      <div class="col-6 text-left mb-1">
                        <span>sub-total:</span>
                      </div>
                      <div class="col-6 text-left mb-1">{{ config.moneda + venta.subtotal.toFixed(2) }}</div>
                      <div class="col-6 text-left mb-1">iva:</div>
                      <div class="col-6 text-left mb-1">{{ config.moneda + venta.iva.toFixed(2) }}</div>
                      <div class="col-6 text-left mb-1" style="font-size: 16px">
                        <b>TOTAL:</b>
                      </div>
                      <div class="col-6 text-left mb-1" style="font-size: 16px">
                        <b>{{ config.moneda + venta.total.toFixed(2) }}</b>
                      </div>
                      <hr class="hr" />
                      <div class="col-12 text-left">
                        <label for="categoria" class="">Forma de pago</label>
                        <b-form-select required class="form-control select" v-model="venta.formaPago" :options="formasDePago" value-field="nombre" text-field="nombre">
                        </b-form-select>
                      </div>
                    </div>
                    <div class="row mt-2 px-4">
                      <div class="col-10">
                        <div class="text-left mb-1">
                          <span>Cliente:</span>
                        </div>

                        <input hidden class="form-control" type="text" :value="clienteSel" name="" id="" />
                        <input required readonly class="form-control" type="text" :value="venta.nombreCliente" name="" id="" />
                      </div>
                      <div class="col-2 mt-4 pl-0">
                        <b-button class="btn btn-light w-10" @click="gotoClientes()">+</b-button>
                      </div>
                      <div class="col-12 mt-2">
                        <div class="text-left mb-1">
                          <span>Descripción / Referencia:</span>
                        </div>
                        <input class="form-control" type="text" v-model="venta.descripcion" name="" id="" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 text-left mt-2">
                        <label for="categoria" class="">Cuenta origen</label>
                        <b-form-select required class="form-control select" v-model="venta.cuentaOrigen" :options="accountsList" value-field="cuenta" text-field="titulo">
                        </b-form-select>
                      </div>
                      <div class="col-12 text-left mt-2">
                        <label for="categoria" class="">Cuenta destino</label>
                        <b-form-select required class="form-control select" v-model="venta.cuentaDestino" :options="accountsList" value-field="cuenta" text-field="titulo">
                        </b-form-select>
                      </div>
                    </div>
                    <div class="row my-3 px-3">
                      <div class="col-6">
                        <b-button @click="cleanSale(indexVenta)" class="btn btn-light w-100">Cancelar</b-button>
                      </div>
                      <div class="col-6">
                        <b-button @click="saveData(itemSel)" class="btn btn-primary w-100">Guardar</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- tabla productos -->
              <div class="row m-0 p-0">
                <div class="col-sm-12 p-0 col-md-8">
                  <div class="bg-primary py-0">
                    <h5 class="mt-1 text-light text-center" style="padding: 3px">Productos</h5>
                  </div>
                </div>
              </div>

              <div class="row mt-0 mr-0">
                <div class="col-md-8 pr-0">
                  <div class="row text-center">
                    <div v-if="productList == 0 && category_id != 0" class="bg-alert-primary mt-3">
                      <h5 class="text-alert pt-2"><i class="fa fa-exclamation-triangle"></i> No hay productos en esta categoría</h5>
                    </div>
                    <div v-if="category_id == 0" class="bg-alert-primary mt-2">
                      <h5 class="text-alert pt-1"><i class="fa fa-exclamation-triangle"></i> Selecciona una categoría</h5>
                    </div>

                    <div @click="showModal(item, indexVenta)" class="col-lg-3 py-2 col-md-4 py-0 px-2 mt-0" v-for="item in productList" :key="item.id">
                      <product-vue :product="item" class="zoom" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- <div class="movil">
          <form>
            <div class="row">
              <div class="p-0 border border-2">
                <div class="bg-primary py-1">
                  <h4 class="mt-1 text-light text-center">Categorías</h4>
                </div>
                <div class="col-12 p-0 border-2">
                  <div class="inner only-this-horizon-scrollbar" style="">
                    <div class="scroll-container diff-size">
                      <div @click="getProductByCategory(item.id)" class="scroll-section" v-for="item in card_home" :key="item.id">
                        <categories-vue :product="item" class="zoom" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-5 m-0">
                <div class="col-md-8 p-0">
                  <div class="row text-center">
                    <div class="bg-primary py-1">
                      <h4 class="mt-1 text-light">Productos</h4>
                    </div>
                    <div v-if="productList == 0 && category_id != 0" class="bg-alert-primary mt-5">
                      <h6 class="text-alert text-center pt-2"><i class="fa fa-exclamation-triangle"></i> No hay productos en esta categoría</h6>
                    </div>
                    <div v-if="category_id == 0" class="bg-alert-primary mt-5">
                      <h6 class="text-alert text-center pt-2"><i class="fa fa-exclamation-triangle"></i> Selecciona una categoría</h6>
                    </div>

                    <div class="mt-3 p-1">
                      <div @click="showModal(item)" class="col-lg-3 py-2 col-md-4 py-0 px-2 mt-3" v-for="item in productList" :key="item.id">
                        <product-vue :product="item" class="zoom" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-4 mt-4 px-0 span6 cel_big pull-right text-center">
                <div class="ml-1">
                  <div class="bg-primary py-1">
                    <h4 class="mt-1 text-light">Resumen</h4>
                  </div>
                  <div class="border">
                    <b-list-group>
                      <div v-for="it of cart" :key="it.id" button>
                        <div class="px-3 hover">
                          <div class="border-top border-bottom row py-3 hover">
                            <div @click="deleteProduct(it)" class="col-1 text-right">
                              <i class="fa fa-trash-o text-red" />
                            </div>
                            <div class="col-6 text-left">
                              {{ it.nombre + "/" + config.moneda + it.precio_venta }}
                            </div>
                            <div class="col-1">{{ it.cantidad }}</div>
                            <div class="col-2 text-left">{{ config.moneda + (it.precio_venta * it.cantidad).toFixed(2) }}</div>
                          </div>
                        </div>
                      </div>
                    </b-list-group>
                    <div class="row mt-3 px-3">
                      <div class="col-md-12 mb-2 mt-2 text-left">
                        <b-form-checkbox @change="calculateTotal()" size="lg" id="checkbox-1" v-model="con_iva" name="checkbox-1" value="1" unchecked-value="0">
                          <span for="observaciones" class="h6">Iva</span>
                        </b-form-checkbox>
                      </div>
                      <div class="col-6 text-left mb-1">
                        <span>sub-total:</span>
                      </div>
                      <div class="col-6 text-left mb-1">{{ config.moneda + subtotal.toFixed(2) }}</div>
                      <div class="col-6 text-left mb-1">iva:</div>
                      <div class="col-6 text-left mb-1">{{ config.moneda + iva.toFixed(2) }}</div>
                      <div class="col-6 text-left mb-1" style="font-size: 16px">
                        <b>TOTAL:</b>
                      </div>
                      <div class="col-6 text-left mb-1" style="font-size: 16px">
                        <b>{{ config.moneda + total.toFixed(2) }}</b>
                      </div>
                      <hr class="hr" />
                      <div class="col-12 text-left">
                        <label for="categoria" class="">Forma de pago</label>
                        <b-form-select required class="form-control select  " v-model="formaPago" :options="formasDePago" value-field="nombre" text-field="nombre">
                        </b-form-select>
                      </div>
                    </div>
                    <div class="row mt-3 mb-3 px-4">
                      <div class="col-10">
                        <div class="text-left mb-1">
                          <span>Cliente:</span>
                        </div>
                        <input class="form-control" type="text" v-model="nombreCliente" name="" id="" />
                      </div>
                      <div class="col-2 mt-4 pl-0">
                        <b-button class="btn btn-light w-10" @click="gotoClientes()">+</b-button>
                      </div>
                    </div>
                    <div class="row mt-3 mb-5 px-3">
                      <div class="col-6">
                        <b-button @click="cleanSale()" class="btn btn-light w-100">Cancelar</b-button>
                      </div>
                      <div class="col-6">
                        <b-button @click="saveData()" class="btn btn-primary w-100">Guardar</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div> -->
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ProductVue from "./Product.vue";
import CategoriesVue from "./Category.vue";
import Customers from "./Customers.vue";
import { eventBus } from "../../../../main";

export default {
  components: { ProductVue, CategoriesVue, Customers },
  data() {
    return {
      fields: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        {
          key: "cliente",
          label: "Cliente",
          sortable: true,
          class: "text-center",
        },
        { key: "fecha", label: "Fecha ", sortable: true, class: "text-center" },
        {
          key: "sucursal",
          label: "Sucursal",
          sortable: true,
          class: "text-center",
        },
        {
          key: "total_venta",
          label: "Total Venta ",
          sortable: true,
          class: "text-center",
        },
        {
          key: "total_costos",
          label: "Total costos ",
          sortable: true,
          class: "text-center",
        },
        {
          key: "Margen",
          label: "Margen",
          sortable: true,
          class: "text-center",
        },
        {
          key: "estado",
          label: "Estado",
          sortable: true,
          class: "text-center",
        },
        { key: "action", label: "", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      fields2: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        {
          key: "articulo",
          label: "Articulo",
          sortable: true,
          class: "text-center",
        },
        {
          key: "cantidad",
          label: "Cantidad ",
          sortable: true,
          class: "text-center",
        },
        {
          key: "precio_unit",
          label: "Precio unit",
          sortable: true,
          class: "text-center",
        },
        {
          key: "sub_total",
          label: "Sub total ",
          sortable: true,
          class: "text-center",
        },

        { key: "action", label: "acción", tdClass: "dt-body-right" },
        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      items: [],
      row: {},
      card_home: [],
      table: "meca_categorias",
      company_id: 0,
      userId: 0,
      rol: 0,
      productList: [],
      productListBk: [],
      cart: [],
      formasDePago: [{ nombre: "Efectivo" }, { nombre: "Tarjeta de crédito" }, { nombre: "Crédito" }],
      formaPago: "Efectivo",
      subtotal: 0,
      total: 0,
      iva: 0,
      cliente_id: 0,
      nombreCliente: null,
      con_iva: 1,
      listaClientes: [],
      fieldsCustomer: [
        {
          key: "nombre",
          label: "Cliente",
          sortable: true,
          class: "text-center",
        },
        { key: "action", label: "", tdClass: "dt-body-right" },
      ],
      descripcion: "--",
      currentPage: 0,
      perPage: 0,
      filter: "",
      filterOn: "",
      sortBy: "",
      sortDesc: "",
      sortDirection: "",
      onFiltered: "",
      textoABuscar: "",
      category_id: 0,
      accountsList: [],
      cuentaOrigen: "400001",
      cuentaDestino: "100001",
      fecha: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
      config: {},
      listaPuestos: [],
      puesto_id: null,
      allProducts: [],
      carritoVenta: [],
      plantillaVenta: {
        id: 0,
        puesto_id: null,
        fecha: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        cart: [],
        con_iva: 1,
        subtotal: 0,
        iva: 0,
        total: 0,
        formaPago: "Efectivo",
        nombreCliente: null,
        descripcion: null,
        cuentaOrigen: "400001",
        cuentaDestino: "100001",
        row: {},
      },
      tabIndexs: 0,
      itemSel: 0,
      listaVendedores: [],
    };
  },
  computed: {
    clienteSel() {
      let resulta = eventBus.$on("clientesel", (data) => {
        console.log(data);
        this.carritoVenta[this.itemSel].cliente_id = data.id;
        this.carritoVenta[this.itemSel].nombreCliente = data.nombre;
        this.$forceUpdate();
        return data.id;
      });
      console.log("resulta", resulta);
      return resulta;
    },
  },
  async mounted() {
    try {
      this.$isLoading(true);

      this.userId = localStorage.getItem("user_id");
      this.company_id = localStorage.getItem("company_id");
      this.rol = localStorage.getItem("rol");
      this.config = JSON.parse(localStorage.getItem("config"));

      await this.getCategories();
      await this.getClientes();
      await this.getAccounts();
      await this.getPositions();
      await this.getallProducts();
      await this.getUsuarios();
      this.agregarVenta();

      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
    }
  },
  methods: {
    agregarVenta() {
      let index = this.carritoVenta.length;
      let cv = {
        id: index + 1,
        puesto_id: null,
        fecha: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        cart: [],
        con_iva: 1,
        subtotal: 0,
        iva: 0,
        total: 0,
        formaPago: "Efectivo",
        nombreCliente: null,
        descripcion: null,
        cuentaOrigen: "400001",
        cuentaDestino: "100001",
        row: {},
        usuario_id: this.userId,
      };
      this.carritoVenta.push(cv);
    },
    async getUsuarios() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: "usuarios/getAllByCompany/" + this.company_id,
        });
        this.$isLoading(false);
        console.log("---", res);
        if (res.length > 0) {
          this.listaVendedores = res;
        } else {
          this.listaVendedores = [];
        }
      } catch (error) {
        this.listaVendedores = [];
        console.error("error", error);
      }
    },
    async getallProducts() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: "meca_productos/getAll/" + this.company_id,
        });
        this.$isLoading(false);
        console.log("---", res);
        if (res.length > 0) {
          this.allProducts = res;
        } else {
          this.allProducts = [];
        }
      } catch (error) {
        this.allProducts = [];
        console.error("error", error);
      }
    },
    async getPositions() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "/meca_puestos/getAllByCompany/" + this.company_id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.listaPuestos = res;
        } else {
          this.listaPuestos = [];
        }
      } catch (error) {
        this.listaPuestos = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async getAccounts() {
      try {
        let res = await this.$store.dispatch("get", {
          path: "meca_cuentas/getAllByCompany/" + this.company_id,
        });
        console.log(res);
        if (res.length > 0) {
          this.accountsList = res;
        } else {
          this.accountsList = [];
        }
      } catch (error) {
        this.accountsList = [];
        console.error("error", error);
      }
    },
    buscar() {
      //textoABuscar
      if (this.textoABuscar.length > 0) {
        let resultadoFiltro = this.allProducts.filter((x) => x.nombre.toUpperCase().trim().includes(this.textoABuscar.toUpperCase().trim()));
        if (resultadoFiltro.length > 0) {
          this.productList = resultadoFiltro;
        } else {
          this.productList = [];
        }
      } else {
        this.productList = this.productListBk;
      }
    },
    selCustomer(item) {
      this.cliente_id = item.id;
      this.nombreCliente = item.nombre;
      this.carritoVenta[this.itemSel].nombreCliente = item.nombre;
      this.$bvModal.hide("modalClientes");
    },
    async getClientes() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: "meca_clientes/getAllByCompany/" + this.company_id,
        });
        this.$isLoading(false);
        if (res.length > 0) {
          this.listaClientes = res;
        }
      } catch (error) {
        this.listaClientes = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async cleanSale(id) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Seguro eliminar venta?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          this.carritoVenta.splice(id, 1);
          this.productList = [];
          this.productListBk = this.productList;
          // this.calculateTotal();
        } catch (error) {
          this.$isLoading(false);
        }
      }
    },
    gotoClientes() {
      this.$bvModal.show("modalClientes");
    },
    deleteProduct(item, indexVenta) {
      let tt = this.carritoVenta[indexVenta].cart.indexOf(item);
      console.log(tt);
      this.carritoVenta[indexVenta].cart.splice(tt, 1);
      this.calculateTotal(indexVenta);
      //alert()
    },
    calculateTotal(indexVenta, e) {
      try {
        console.log("calculate total", indexVenta);
        console.log("valor", e);
        if (e != undefined) {
          this.carritoVenta[indexVenta].con_iva = e;
        }

        this.carritoVenta[indexVenta].subtotal = this.carritoVenta[indexVenta].cart.map((item) => item.cantidad * item.precio_venta).reduce((p, c) => p + c, 0);
        if (this.carritoVenta[indexVenta].con_iva == 1) {
          console.log("1");
          this.carritoVenta[indexVenta].iva = this.carritoVenta[indexVenta].subtotal * 0.15;
        } else {
          console.log("0");
          this.carritoVenta[indexVenta].iva = 0;
        }
        this.carritoVenta[indexVenta].total = this.carritoVenta[indexVenta].subtotal + this.carritoVenta[indexVenta].iva;
        this.$forceUpdate();
      } catch (error) {
        console.log("error", error);
      }
    },
    addProductToCart() {
      console.log("-------->", this.carritoVenta[this.itemSel]);
      this.carritoVenta[this.itemSel].cart.push(this.row);
      console.log(this.carritoVenta);
      this.row = {};
      this.calculateTotal(this.itemSel);
      this.$forceUpdate();

      this.$bvModal.hide("exampleModalCenter");
      console.log("carrito venta---->", this.carritoVenta);
    },
    showModal(item, indexVenta) {
      this.row = item;
      this.row.cantidad = 1;
      this.row.indexVenta = indexVenta;
      this.$bvModal.show("exampleModalCenter");
      this.$forceUpdate();
    },
    async getProductByCategory(item) {
      this.category_id = item;
      console.log("getProductByCategory");
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: "meca_productos/getAllVisibleByCategoryCompany/" + this.company_id + "/" + item,
        });
        this.$isLoading(false);
        if (res.length > 0) {
          console.log(this.productList);
          this.productList = res;
          this.productListBk = this.productList;
        } else {
          this.productList = [];
          this.productListBk = this.productList;
        }
      } catch (error) {
        this.productList = [];
        this.productListBk = this.productList;
        console.error("error", error);
      }
    },
    async getCategories() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: this.table + "/getAllVisibleByCompany/" + this.company_id,
        });
        this.$isLoading(false);
        if (res.length > 0) {
          this.card_home = res;
        }
      } catch (error) {
        this.card_home = [];
        console.error("error", error);
      }
    },
    async saveData(indexVenta) {
      console.log("", this.total_venta);

      try {
        if (this.carritoVenta[indexVenta].nombreCliente == null) {
          this.$swal({
            title: "Aviso",
            text: "debes seleccionar cliente",
            icon: "error",
          });
          return;
        }
        if (this.carritoVenta[indexVenta].cart.length == 0) {
          this.$swal({
            title: "Aviso",
            text: "debes agregar productos",
            icon: "error",
          });
          return;
        }
        if (this.carritoVenta[indexVenta].total == 0) {
          this.$swal({
            title: "Aviso",
            text: "No puedes ingresar venta en 0",
            icon: "error",
          });
          return;
        }
        console.log("save");
        //let data = Object.assign({} , this.row)
        //data.empresa_id = this.company_id
        let res;
        this.$isLoading(true);
        let data = {
          invoice: {
            cliente_id: this.carritoVenta[indexVenta].cliente_id,
            nombre: this.carritoVenta[indexVenta].nombreCliente,
            iva: this.carritoVenta[indexVenta].iva,
            subtotal: this.carritoVenta[indexVenta].subtotal,
            total_venta: this.carritoVenta[indexVenta].total,
            empresa_id: this.company_id,
            usuario_id: this.userId,
            estado: "Pagada",
            descripcion: this.carritoVenta[indexVenta].descripcion,
            por_iva: 15,
            fecha_i: this.carritoVenta[indexVenta].fecha,
            fecha_f: this.carritoVenta[indexVenta].fecha,
            total_costos: this.carritoVenta[indexVenta].cart.map((item) => item.cantidad * item.precio_compra).reduce((p, c) => p + c, 0),
            margen:
              this.carritoVenta[indexVenta].total -
              this.carritoVenta[indexVenta].iva -
              this.carritoVenta[indexVenta].cart.map((item) => item.cantidad * item.precio_compra).reduce((p, c) => p + c, 0),
            metodo_pago: this.carritoVenta[indexVenta].formaPago,
            puesto_id: this.carritoVenta[indexVenta].puesto_id,
          },
          detail: this.carritoVenta[indexVenta].cart,
          accounts: {
            cuentaOrigen: this.carritoVenta[indexVenta].cuentaOrigen,
            cuentaDestino: this.carritoVenta[indexVenta].cuentaDestino,
          },
        };
        res = await this.$store.dispatch("post", {
          path: "meca_ventas/new",
          data: data,
        });
        this.$isLoading(false);

        if (res.error) {
          this.$isLoading(false);
          this.$swal({
            title: "Aviso",
            text: "Error: " + res.error,
            icon: "error",
          });
        } else {
          this.cleanSale(indexVenta);
          this.$swal({
            title: "Aviso",
            text: "Datos almacenados correctamente",
            icon: "success",
          });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el registro?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          let res = await this.$store.dispatch("delete", {
            path: this.table + "/delete/" + item.id,
          });
          if (res.error) {
            this.$swal({
              title: "Aviso",
              text: "Error: " + res.error,
              icon: "error",
            });
          } else {
            this.getData();
            this.$swal({
              title: "Aviso",
              text: "Eliminado correctamente",
              icon: "success",
            });
          }
        } catch (error) {
          this.items = [];
          console.error("error--->", error);
        }
      }
    },
  },
};
</script>

<style>
.table-b-table-default {
  background-color: var(--body-color);
}
[data-theme="dark"] {
  .table-b-table-default {
    background-color: var(--body-color);
  }
}

.border-table {
  border: 1px solid rgb(216, 216, 216) !important;
  border-radius: 5px;
  /* padding:5px; */
  text-align: center !important;
}

/* carusel */
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 98%;

  scroll-snap-type: mandatory;
  scroll-behavior: smooth;

  word-wrap: break-word !important;
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;

  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}

.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

.cel_big {
  right: 0px;
  /* border:1px solid red; */
  /* //height: 100% !important; */
  /* background-color: rgb(231, 231, 231); */
  position: absolute;
  height: 800px;
  bottom: 20px;
  top: 15px;
  min-height: 600px;
}
.hr {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.text-red {
  color: red;
}
@media screen and (max-width: 720px) {
  .cel_big {
    position: static;
    min-height: 0px !important;
    height: 0px !important;
  }
}
</style>
