<template>
  <div>
    <!-- <div class="row g-2 row-deck mb-2">
            <div class="col-xl-3 col-md-6" v-for="(content, index) in top_content" :key="index">
                <div class="card">
                    <div class="card-body">
                        <div class="float-right">
                            <i class="h4 ms-3" :class="content.icon"></i>
                        </div>
                        <h4 class="mt-0 pt-1">{{ content.count }}</h4>
                        <p class="text-muted mb-0">{{ content.projects }}</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card">
                    <div class="card-body">
                        <form>
                            <div class="form-group mb-0">
                                <label>Search</label>
                                <div class="input-group mb-0">
                                    <input type="text" class="form-control" placeholder="Search..." aria-describedby="project-search-addon" />
                                    <div class="input-group-append">
                                        <button class="btn btn-danger" type="button" id="project-search-addon"><i class="fa fa-search search-icon font-12"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>  -->
    <h6 class="ml-3"><b>MIS TABLEROS</b></h6>
    <input @click="showModal('', 1)" type="button" class="btn btn-primary m-4" value="Nuevo Tablero" />
    <button @click="getData('all')" data-toggle="tooltip" data-placement="top" title="Ver todo" class="px-3 mx-1 btn btn-light"><i class="fa-solid fa-eye"></i></button>
    <button @click="getData('active')" data-toggle="tooltip" data-placement="top" title="Ver tableros activos personales" class="px-3 mx-1 btn btn-light">
      <i class="fa-solid fa-user"></i>
    </button>
    <button @click="getDataTabs('publico')" data-toggle="tooltip" data-placement="top" title="Ver tableros públicos" class="px-3 mx-1 btn btn-light">
      <i class="fa-solid fa-user-group"></i>
    </button>

    <!-- <input @click="getData('active')" type="button" class="btn btn-primary m-4" value="tableros activos (personales)" />
    <input @click="getData('all')" type="button" class="btn btn-primary m-4" value="Ver todos los tableros (personales)" />
    <input @click="getDataTabs('publico')" type="button" class="btn btn-primary m-4" value="Ver tableros públicos" /> -->

    <div class="row">
      <div class="col-12">
        <!-- <b-tabs nav-class="tab-card mb-2 mt-4">
                        <b-tab v-for="(tab, index) in tabs" :key="index">
                            <template #title>
                                {{tab.tab_name}} <span class="badge" :class="tab.tab_class">{{ tab.tab_count }}</span>
                            </template>
                        </b-tab>
                </b-tabs> -->
        <div class="row g-2 row-deck">
          <div class="col-lg-4 col-md-4 col-sm-12 pointer" v-for="(project, index) in items" :key="index">
            <div class="card">
              <div class="row mt-3 bg-gray">
                <div class="col-9" @click="showKanban(project)">
                  <p class="ml-2 mt-0 mb-1 text-center">
                    <b
                      ><a class="text-primary pointer"> <i v-if="project.favorito == 1" class="fa fa-star yellow mr-1"></i> {{ project.titulo }}</a></b
                    >
                  </p>
                  <p class="text-center" style="font-style: italic">{{ project.tipo == "i" ? "Eres Colaborador" : "" }}</p>
                </div>
                <div class="col-1">
                  <div class="mr-2">
                    <b-dropdown v-if="project.tipo != 'i'" id="dropdown-1" text="" variant="primary">
                      <b-dropdown-item @click="editRow(project)">Editar </b-dropdown-item>
                      <b-dropdown-item @click="showInviteModal(project)">Invitar a colaborador</b-dropdown-item>
                      <!-- <b-dropdown-item @click="delRow(project)">Archivar</b-dropdown-item> -->
                    </b-dropdown>
                  </div>
                </div>
              </div>
              <div class="card-body p-md-1 pointer" @click="showKanban(project)">
                <!-- <p class="text-muted text-uppercase mb-0 small">{{  title }}</p> -->
                <p class="pl-1 pr-1">{{ project.descripcion }}</p>
                <label style="padding-left: 5px" v-if="project.archivado == 1">
                  <span class="py-1 px-2" style="border-radius: 8px; background-color: rgba(226, 80, 104, 0.205); color: rgb(252, 71, 101)"
                    ><i class="fa-solid fa-folder mr-1"></i> {{ project.archivado == 1 ? "Archivado" : "" }}</span
                  ></label
                >

                <!-- <ul class="list-inline">
                                    <li class="list-inline-item pe-lg-4" v-for="(item, index) in items" :key="index">
                                        <h4 class="mb-0">{{ item.attachments }}</h4>
                                        <p class="text-muted">{{ item.title }}</p>
                                    </li>
                                </ul> -->

                <!-- <div class="project-members mb-4">
                                    <label class="me-3">Team :</label>
                                    <a href="#" title="" v-for="(team, index) in teams" :key="index">
                                        <img class="avatar sm rounded" :src="`${team}`" alt="friend">
                                    </a>
                                </div> -->

                <!-- <label class="small d-flex justify-content-between">Task completed: <span class="text-custom">{{ start_end }}</span></label>
                                <div class="progress mt-1" style="height: 7px;">
                                    <div class="progress-bar" :class="classes" role="progressbar" :aria-valuenow="width" aria-valuemin="0" aria-valuemax="100" :style="{ width: width+'%'}"></div>
                                </div> -->
              </div>
            </div>

            <!-- <teamsboard-component
                            :title = "project.title"
                            :sub_title = "project.sub_title"
                            :content = "project.content"
                            :items = "project.items"
                            :teams = "project.teams"
                            :start_end = "project.task_completed.start_end"
                            :classes = "project.task_completed.class"
                            :width = "project.task_completed.width"
                        ></teamsboard-component> -->
          </div>
        </div>
      </div>
    </div>

    <b-modal id="kanbanModal" tabindex="-1" size="md" centered :title="modalTitle" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-md-12 mb-2">
              <label for="titulo" class="">Titulo</label><input required v-model="row.titulo" class="form-control" type="text" name="nombre" id="nombre" />
            </div>
            <div class="col-md-12 mb-2">
              <label for="descripcion" class="">Descripción</label>
              <b-form-textarea id="textarea" v-model="row.descripcion" rows="3" max-rows="6"></b-form-textarea>
            </div>
            <!-- <div class="col-md-6">
                            <label for="contacto" class="">Nombre contacto</label><input class="form-control" v-model="row.nombre_contacto" type="text" name="contacto" id="contacto">
                        </div>
                        <div class="col-md-6">
                            <label for="contacto" class="">Email</label><input class="form-control" v-model="row.email" type="email" name="contacto" id="contacto">
                        </div>
                        <div class="col-md-6 mb-2">
                            <label for="estado" class="">Estado</label>
                            <b-form-select required class="form-control select  "  v-model="row.estado" :options="listaEstado" value-field="estado" text-field="estado">
                            </b-form-select>
                        </div>
                        <div class="col-md-12 mb-2">
                            <label for="observaciones" class="">Observaciones</label><input class="form-control" v-model="row.observaciones" type="text" name="observaciones" id="observaciones">
                        </div> -->
            <b-form-checkbox class="ml-4" size="lg" id="checkbox-3" v-model="row.favorito" name="checkbox-3" value="1" unchecked-value="0">
              <span for="Favorito" class="h6">Favorito</span>
            </b-form-checkbox>
            <b-form-checkbox class="ml-4" size="lg" id="checkbox-1" v-model="row.archivado" name="checkbox-1" value="1" unchecked-value="0">
              <span for="Archivado" class="h6">Archivado</span>
            </b-form-checkbox>
            <b-form-checkbox class="ml-4 mb-3" size="lg" id="checkbox-2" v-model="row.publico" name="checkbox-1" value="1" unchecked-value="0">
              <span for="Archivado" class="h6">Tablero Público (a nivel de empresa)</span>
            </b-form-checkbox>
          </div>
          <div class="text-right">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('kanbanModal')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>
    <!-- modal compartir tablero -->
    <b-modal id="inviteModal" tabindex="-1" size="md" centered :title="'Compartir tablero'" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="invite()">
          <div class="row">
            <div class="col-md-12 mb-2">
              <label for="titulo" class="">Ingresa correo del colaborador:</label>
              <input required v-model="correoColaborador" class="form-control" type="email" name="nombre" id="nombre" />
            </div>
          </div>
          <div class="text-right">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('inviteModal')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
// import Teamsboard from '@/components/core/Teamsboard.vue'
export default {
  name: "TeamsBoardComponent",
  components: {
    // 'teamsboard-component':Teamsboard
  },
  data() {
    return {
      top_content: [
        {
          count: "24",
          projects: "Total Projects",
          icon: "fa fa-archive",
        },
        {
          count: "18",
          projects: "Completed Projects",
          icon: "fa fa-th",
        },
        {
          count: "06",
          projects: "Pending Projects",
          icon: "fa fa-file",
        },
      ],
      tabs: [
        {
          tab_name: "Active",
          tab_count: "5",
          tab_class: "bg-success",
        },
        {
          tab_name: "Paused",
          tab_count: "3",
          tab_class: "bg-info",
        },
        {
          tab_name: "Waiting",
          tab_count: "5",
          tab_class: "bg-warning",
        },
        {
          tab_name: "Delete",
          tab_count: "2",
          tab_class: "bg-danger",
        },
      ],
      items: [],
      table: "meca_kanban",
      row: {},
      company_id: 0,
      userId: 0,
      rol: 0,
      modalTitle: "Nuevo Tablero",
      listaEstado: [{ estado: "Activo" }, { estado: "Inactivo" }],
      singleTable: "Tablero",
      textTable: "Tableros Kanban",
      correoColaborador: "",
      kanban_id: 0,
    };
  },
  mounted() {
    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");
    this.getData("active");
  },
  methods: {
    async invite() {
      let data = {
        meca_kanban_id: this.kanban_id,
        email: this.correoColaborador,
      };
      await this.$store.dispatch("post", { path: this.table + "/invite", data: data });
      this.$bvModal.hide("inviteModal");
    },
    async showInviteModal(it) {
      this.kanban_id = it.id;
      console.log(it);
      this.$bvModal.show("inviteModal");
    },
    editRow(item) {
      console.log(item);
      this.row = item;
      this.showModal(item, 2);
    },
    async saveData() {
      try {
        console.log("save");
        let data = Object.assign({}, this.row);
        data.usuario_id = this.userId;
        data.empresa_id = this.company_id;
        let res;
        // delete(this.row.tipo)
        delete data.tipo;
        if (this.row.id) {
          delete data.id;
          res = await this.$store.dispatch("put", { path: this.table + "/modify/" + this.row.id, data: data });
        } else {
          res = await this.$store.dispatch("post", { path: this.table + "/new", data: data });
        }
        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.row = {};
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
          this.$bvModal.hide("kanbanModal");
          await this.getData("active");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    showKanban(item) {
      console.log(item.id);
      this.$router.push({ name: "kanban", params: { id: item.id } });
    },
    showModal(item, id) {
      console.log("----->", item);
      if (id == 1) {
        this.modalTitle = "Nuevo " + this.singleTable;
        this.row = {};
        this.$bvModal.show("kanbanModal");
      } else {
        this.row = item;
        this.modalTitle = "Editar " + this.singleTable;
        this.$bvModal.show("kanbanModal");
        console.log(this.row);
      }
    },
    async getData(filter) {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + "/getAllByUser/" + this.userId + "/" + filter });
        if (res.length > 0) {
          this.items = res;
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async getDataTabs(filter) {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + "/getAllByCompany/" + this.company_id + "/" + filter });
        if (res.length > 0) {
          this.items = res;
        } else {
          this.items = [];
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
.yellow {
  color: yellow;
}
</style>
